<template>
  <v-container>
    <v-row>
      <v-col col="12">
        <v-toolbar dark color="primary">
          Terminos y condiciones
        </v-toolbar>

      </v-col>

      <v-col cols="12" md="12">
        <v-card elevation="10">

          <v-card-text>
            <v-textarea
              outlined
              auto-grow
              readonly
              v-model="terminos"
              label= "Terminos y Condiciones"
            >
            </v-textarea>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data () {
    return {
      terminos: ''

    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      this.$http.get('api/v1/getlegal').then(response => {
        console.log(response.data)
        this.terminos = response.data.Terminos
      }).catch(error => { console.log(error) })
    }

  }
}
</script>
